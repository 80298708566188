import Layout from "../component/Layout";
import { useState } from "react";
import axios from 'axios'
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import { BASE_URL } from "./../Variable"

export default function CreateCampaign() {
  const [currency, setCurrency] = useState();
  const [err,setErr]=useState(false)
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    const {name,value} = e.target;
    setCurrency((prev) => ({ ...prev, [name]: value }));
  };

  const handleVal=()=>{
    if(!currency?.name || !currency?.sellrate || !currency?.symbol || !currency?.sellrate){
      return setErr(true)
    }
    createCurrency()
  }
  const createCurrency= async ()=>{
    try {
      const token = localStorage.getItem('token');
      const res = await axios.post(BASE_URL+'/currency/',currency,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      console.log(res)
      Swal.fire({
        title: "Success!",
        text: "currency created corrected!",
        icon: "success",
      });
      navigate('/currencies')
    } catch (error) {
      console.log(error.message)
    }
  }

  console.log(currency)

  return (
    <>
      <Layout>
        <div className="flex justify-between">
          <h1>Create Currency</h1>
          <div className="flex gap-5">
            <div>
              <button className="bg-gray-500 text-white px-5 py-1 rounded">
                Cancel
              </button>
            </div>
            <div>
              <button className="color_blue text-white px-5 py-1 rounded" onClick={()=> handleVal()}>
                Save
              </button>
            </div>
          </div>
        </div>
        <hr className="mt-3 border-gray-300" />

        <div className="bg-white h-auto w-full py-10 mt-5 px-5">

          <div className="form2 ">
          {!currency?.name && err && (
                <span className="err_msg">please enter currency name</span>
              )}
            <input
              type="text"
              name="name"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
              Name
            </label>
          </div>

          <div className="form">
          {!currency?.rate && err && (
                <span className="err_msg">please enter currency rate</span>
              )}
            <input
              type="text"
              name="sellrate"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
              SellRate
            </label>
          </div>

          <div className="form">
          {!currency?.rate && err && (
                <span className="err_msg">please enter currency rate</span>
              )}
            <input
              type="text"
              name="buyrate"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
              buyRate
            </label>
          </div>

          <div className="form ">
          {!currency?.symbol && err && (
                <span className="err_msg">please enter currency symbol</span>
              )}
            <input
              type="text"
              name="symbol"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
              Symbol
            </label>
          </div>


        </div>
      </Layout>
    </>
  );
}
