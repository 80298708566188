import Layout from "./../component/Layout";
import { FaHistory,FaCaretUp,FaCaretDown } from "react-icons/fa";
import { LuTally1 } from "react-icons/lu";
import { useState, useEffect } from "react";
import Loader from "../component/Loader";
import axios from "axios";
import { BASE_URL } from "./../Variable"


export default function Dashboard() {
  const [rates, setRates] = useState();
  const [isLoading,setLoading]=useState(true)

  const getAll = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem('token');
      const res = await axios.get(BASE_URL+"/currency/",{
        headers:{
          Authorization:`Bearer ${token}`
        }
      });
      setRates(res.data.data);
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
    {isLoading? <Loader/>  :     <Layout>
        <div className="grid grid-cols-4">
          {rates &&
            rates.map((item) => (
              <div className="bg-white h-auto w-56 p-3 mt-3" key={item._id}>
                <div className="flex">
                  <div >
                    <h1>{item.symbol} </h1>
                    <div className="flex gap-2 items-center">
                      <FaCaretDown/>
                    <h1 className="font-bold text-red-700">{item.sellrate}</h1>


                    <h1 className="font-bold text-green-700">{item.buyrate}</h1>
                    <FaCaretUp/>
                    </div>

                  </div>
                </div>
                <hr className="mt-3" />
                <div className="flex items-center mt-3 gap-2">
                  <FaHistory className="text-xs" />
                  <p className="text-xs">{`current rate for ${item.name}`}</p>
                </div>
              </div>
            ))}
        </div>

        <div className="mt-10">
          <h1>Current Rates</h1>
          <hr className="mt-2 border-gray-300" />
          <div className="bg-white w-full h-auto p-5 mt-5">
            <table>
              <thead className="border-b ">
                <tr className="">
                  <th className="text-left pr-20">Id</th>
                  <th className="text-left py-3 pr-10">Name</th>
                  <th className="text-left py-3 pr-10">symbol</th>
                  <th className="text-left py-3 pr-20">sellrate</th>
                  <th className="text-left py-3 pr-20">buyrate</th>
                  <th className="text-left py-3 pr-20">Action</th>
                </tr>
              </thead>
              <tbody>
                {rates &&
                  rates.map((item) => (
                    <tr className="" key={item._id}>
                      <td className="text-left py-3 pr-20">{item._id.slice(0,8)}</td>
                      <td className="text-left py-3 pr-20">{item.name}</td>
                      <td className="text-left py-3 pr-20">{item.symbol}</td>
                      <td className="text-left py-3 pr-20">{item.sellrate}</td>
                      <td className="text-left py-3 pr-20">{item.buyrate}</td>
                      <td className="text-left py-3 pr-20"> 
                      <button className="color_blue text-white px-5 py-1 rounded">View</button>
                      </td>
                    </tr>
                  ))}
                  
              </tbody>
            </table>
          </div>
        </div>
      </Layout> }

    </>
  );
}
