import Layout from "../component/Layout";

export default function CreateCampaign() {
  return (
    <>
      <Layout>
        <div className="flex justify-between">
          <h1>Create Campaign</h1>
          <div className="flex gap-5">
            <div>
              <button className="bg-gray-500 text-white px-5 py-1 rounded">
                Cancel
              </button>
            </div>
            <div>
              <button className="color_blue text-white px-5 py-1 rounded">
                Save
              </button>
            </div>
          </div>
        </div>
        <hr className="mt-3 border-gray-300" />

        <div className="bg-white h-screen w-full py-5 mt-5 px-5">
          <div className="form ">
            <input
              type="text"
              name="Title"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              // onChange={(e) => handleInputValues(e)}
            />

            <label htmlFor="email" className="form_label">
              Title
            </label>
          </div>

          <div className="form2">
            <textarea
              type="text"
              name="Title"
              className="form_input2"
              autoComplete="off"
              placeholder=" "
              // onChange={(e) => handleInputValues(e)}
            />

            <label htmlFor="email" className="form_label">
              Html Template
            </label>
          </div>




        </div>
      </Layout>
    </>
  );
}
