import Layout from "../component/Layout";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaRegTrashAlt } from "react-icons/fa";
import Loader from "../component/Loader";
import { BASE_URL } from "./../Variable"

export default function ViewCurrencies() {
  const [rates, setRates] = useState();
  const [isLoading,setLoading]=useState(true)

  const getAll = async () => {
    try {
      const token = localStorage.getItem('token');
      const res = await axios.get(BASE_URL+"/currency/",{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      setRates(res.data.data);
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteCurrency= async(id)=>{
    try {
      const token = localStorage.getItem('token');
      const res = await axios.delete(`${BASE_URL}/currency/${id}`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      Swal.fire({
        title: "Success!",
        text: "currency deleted successfully!",
        icon: "success",
      });
      const filterState = rates.filter((item)=> item._id !== id)
      setRates(filterState)
      // console.log(filterState)

    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
    {isLoading? <Loader/> :      <Layout>
        <Link to="/createcurrency">
          <button className="color_blue text-white px-6 py-2 rounded">
            Create Currency
          </button>
        </Link>

        <div className="bg-white w-full h-auto p-5 mt-5">
          <table>
            <thead className="border-b ">
              <tr className="">
                <th className="text-left pr-20">Id</th>
                <th className="text-left py-3 pr-10">Name</th>
                <th className="text-left py-3 pr-10">symbol</th>
                <th className="text-left py-3 pr-20">sellrate</th>
                <th className="text-left py-3 pr-20">buyrate</th>
                <th className="text-left py-3 pr-20">Action</th>
              </tr>
            </thead>
            <tbody>
              {rates &&
                rates.map((item) => (
                  <tr className="" key={item._id}>
                    <td className="text-left py-3 pr-20">
                      {item._id.slice(0, 8)}
                    </td>
                    <td className="text-left py-3 pr-20">{item.name}</td>
                    <td className="text-left py-3 pr-20">{item.symbol}</td>
                    <td className="text-left py-3 pr-20">{item.sellrate}</td>
                    <td className="text-left py-3 pr-20">{item.buyrate}</td>
                    <td className="  flex gap-2 items-center text-left py-3 pr-20">
                      <p className="text-green-500 underline">View</p>
                      <Link to={`/updatecurrency/${item._id}`} >
                        <p className="text_blue underline">Edit</p>
                      </Link>
                      <FaRegTrashAlt className="trash_icon text-red-600 cursor-pointer" onClick={()=> deleteCurrency(item._id)}/>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Layout>}

    </>
  );
}
