import Layout from "../component/Layout";
import { useState } from "react";
import axios from 'axios'
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import { BASE_URL } from "./../Variable"

export default function CreateUser() {
  const [user, setUser] = useState();
  const [err,setErr]=useState(false)
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    const {name,value} = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleVal=()=>{
    if(!user?.fullname || !user?.email || !user?.password || !user?.role){
      return setErr(true)
    }
    createUser()
  }
  const createUser= async ()=>{
    try {
      const token = localStorage.getItem('token');
      const res = await axios.post(BASE_URL+'/user/signup',user,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      Swal.fire({
        title: "Success!",
        text: "user created corrected!",
        icon: "success",
      });
      navigate('/dashboard')
    } catch (error) {
      console.log(error.message)
      Swal.fire({
        title: "error!",
        text: error.message,
        icon: "error",
      });
    }
  }

  return (
    <>
      <Layout>
        <div className="flex justify-between">
          <h1>Create user</h1>
          <div className="flex gap-5">
            <div>
              <button className="bg-gray-500 text-white px-5 py-1 rounded">
                Cancel
              </button>
            </div>
            <div>
              <button className="color_blue text-white px-5 py-1 rounded" onClick={()=> handleVal()}>
                Save
              </button>
            </div>
          </div>
        </div>
        <hr className="mt-3 border-gray-300" />

        <div className="bg-white h-auto w-full py-10 mt-5 px-5">

          <div className="form2 ">
          {!user?.fullname && err && (
                <span className="err_msg">please enter full name</span>
              )}
            <input
              type="text"
              name="fullname"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
              fullname
            </label>
          </div>

          <div className="form">
          {!user?.email && err && (
                <span className="err_msg">please enter email </span>
              )}
            <input
              type="text"
              name="email"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
              email
            </label>
          </div>

          <div className="form ">
          {!user?.password && err && (
                <span className="err_msg">please enter  password</span>
              )}
            <input
              type="text"
              name="password"
              className="form_input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handleSubmit(e)}
            />

            <label htmlFor="email" className="form_label">
            password
            </label>
          </div>

                    
          <div className="form2 mt-10">
          {!user?.role && err && (
                <span className="err_msg">please enter role</span>
              )}
            <select
              type="text"
              name="role"
              className="w-full py-2 border-b border-black"
              autoComplete="off"
              onChange={(e) => handleSubmit(e)}
            >
              <option value="" hidden> Select role</option>
              <option value="admin">admin</option>
            </select>



          </div> 

        </div>
      </Layout>
    </>
  );
}
