import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BASE_URL } from "./../Variable"

export default function Login() {
  const [admin, setAdmin] = useState({});
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const { value, name } = e.target;
    setAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const LoginUser = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${BASE_URL}/user/login`, admin);
      Swal.fire({
        title: "Success!",
        text: "Login successful!",
        icon: "success",
      });
      localStorage.setItem("token", res.data.token);
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if (error.response && error.response.status === 401) {
        Swal.fire({
          title: "Error!",
          text: "Incorrect password or email",
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  const handleVal = () => {
    if (!admin.email || !admin.password) {
      setErr(true);
    } else {
      setErr(false);
      LoginUser();
    }
  };

  return (
    <>
      <div className="w-full h-full flex justify-center items-center py-20 bg-gray-100">
        <div className="h-4/5 w-4/5 shadow border text-center bg-white">
          <div className="py-20">
            <h1 className="text-5xl font-bold text_blue">Login</h1>
            <p className="mt-5">Enter Your Details To Sign Into your panel</p>

            <div className="form3 mt-8">
              {!admin.email && err && (
                <span className="err_msg flex items-start">
                  Please enter email
                </span>
              )}
              <input
                type="text"
                name="email"
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={handleSubmit}
              />
              <label htmlFor="email" className="form_label">
                Email*
              </label>
            </div>

            <div className="form3 mt-8 relative">
              {!admin.password && err && (
                <span className="err_msg flex items-start">
                  Please enter password
                </span>
              )}
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={handleSubmit}
              />
              <label htmlFor="password" className="form_label">
                Password*
              </label>
              <span
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </span>
            </div>

            <div className="mt-8">
              <button
                className="color_blue w-2/4 p-2 text-white rounded"
                onClick={handleVal}
              >
                {loading ? <ClipLoader color="#36d7b7" /> : "Log In"}
              </button>
            </div>
            <p className="mt-5">
              Don't have an account? Contact Admin{" "}
              <span className="underline">isreal@tradegenie.com</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
