import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ViewCampaign from './pages/ViewCurrencies';
import CreateCurrency from './pages/CreateCurrency';
import ViewNewsletter from './pages/ViewNewsletter';
import CreateNewsletter from './pages/CreateNewsletter';
import Subscribers from './pages/Subscribers';
import EditCurrency from './pages/EditCurrency';
import User from './pages/User';
import EditUser from './pages/EditUser';
import CreateUser from './pages/CreateUser';
import Login from './pages/Login';
import Loader from './component/Loader';
import ProtectedRoute from './component/ProtectedRoute';
import './App.css';

function App() {
  const token = localStorage.getItem('token'); // assuming the token is stored in localStorage

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/currencies" element={<ProtectedRoute><ViewCampaign /></ProtectedRoute>} />
          <Route path="/createcurrency" element={<ProtectedRoute><CreateCurrency /></ProtectedRoute>} />
          <Route path="/view-newsletter" element={<ProtectedRoute><ViewNewsletter /></ProtectedRoute>} />
          <Route path="/create-newsletter" element={<ProtectedRoute><CreateNewsletter /></ProtectedRoute>} />
          <Route path="/updatecurrency/:id" element={<ProtectedRoute><EditCurrency /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute><User /></ProtectedRoute>} />
          <Route path="/updateuser/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
          <Route path="/createuser" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
          <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
