import Layout from "../component/Layout";

export default function ViewNewsletter(){
  return(
     <>
     <Layout>
      <button className="color_blue text-white px-6 py-2 rounded">Create Newsletter</button>
      <div className="bg-white w-full h-auto p-5 mt-5">
            <table>
              <thead className="border-b ">
                <tr className="">
                  <th className="text-left pr-20">Title</th>
                  <th className="text-left py-3 pr-10">Sent</th>
                  <th className="text-left py-3 pr-10">Opens</th>
                  <th className="text-left py-3 pr-10">Clicks</th>
                  <th className="text-left py-3 pr-10">unsubscribers</th>
                  <th className="text-left py-3 pr-10">Submitted Date </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="text-left py-3 pr-20">General Message</td>
                  <td className="text-left py-3 pr-10">0 of 5</td>
                  <td className="text-left py-3 pr-10">0 of 5</td>
                  <td className="text-left py-3 pr-10">0 of 5</td>
                  <td className="text-left py-3 pr-10">0 of 5</td>
                  <td className="text-left py-3 pr-10">August 10th 2024 9:50pm</td>
                  <td className="text-green-500 underline">
                    <p>Edit</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
     </Layout>
     </>
  )
}