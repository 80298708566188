import { FaRegBell, FaRegEnvelope } from "react-icons/fa";
import profileImg from "./../assets/images/TradeGenie Logo (1).png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Topbar() {
  const [user, setUser] = useState();
  const [isModal, setIsModal] = useState(false);
  const navigate = useNavigate();
  const openModal = () => {
    setIsModal((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    Swal.fire({
      title: "Success!",
      text: "Logout successful!",
      icon: "success",
    });
    navigate("/login");
  };

  const getUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get("http://localhost:7000/user/loggedin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(res.data.user);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="">
      <div className="flex justify-between bg-white w-full h-auto border p-2 items-center">
        <div className="w-6/12">
          <input
            type="text"
            className="grey_color w-10/12 p-2 rounded"
            placeholder="Enter Search"
          />
        </div>
        <div className="flex items-center gap-5">
          <FaRegBell className="text-2xl" />
          <FaRegEnvelope className="text-2xl" />

          <div className="flex items-center gap-3 ml-10 relative">
            <img src={profileImg} className="w-14 h-14 rounded-full" alt="" onClick={()=> openModal()}/>
            <div>
              <h1 className="">{user?.fullname}</h1>
              <p className="text-sm text-gray-400">
                {user?.email.split("@")[0]}
              </p>
            </div>
          </div>
          {isModal && (
            <div className="absolute bg-white shadow top-20 right-5 h-20 w-auto p-5">
              <div className="">
                <button className="color_blue text-white px-10 py-1 rounded" onClick={handleLogout}>
                  Log Out
                </button>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}
