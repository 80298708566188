import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

export default function Layout({ children }) {
  return (
    <>
      <div className="flex">
        <Sidebar />

        <div className="w-full">
          <Topbar />
          <div className="children p-3 overflow_scroll">
            <div >{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}
