import Layout from "../component/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import Loader from "../component/Loader";
import { BASE_URL } from "./../Variable"


export default function ViewCurrencies() {
  const [users, setUsers] = useState();
  const [isLoading,setLoading]=useState(true)
  
  const navigate = useNavigate()

  const getAll = async () => {
    try {
      const token = localStorage.getItem('token');
      const res = await axios.get(BASE_URL+"/user/",{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      setUsers(res.data.data);
      setLoading(false)
    } catch (error) {
      console.log(error.code)
      if(error.message === "Request failed with status code 401"){
        Swal.fire({
          title: "error!",
          text: "please login again, user unauthorized",
          icon: "error",
        });
        navigate('/login')
      }else{
        Swal.fire({
          title: "error!",
          text: error.message,
          icon: "error",
        });
      }

    }
  };

  const deleteUser= async(id)=>{
    console.log(id)
    try {
      const token = localStorage.getItem('token');
      const res = await axios.delete(`${BASE_URL}/user/${id}`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      Swal.fire({
        title: "Success!",
        text: "user deleted succesfully!",
        icon: "success",
      });
      const filterState = users.filter((item)=> item._id !== id)
      setUsers(filterState)

    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
    {isLoading?<Loader/>:      <Layout>
        <Link to="/createuser">
          <button className="color_blue text-white px-6 py-2 rounded">
            Create user
          </button>
        </Link>

        <div className="bg-white w-full h-auto p-5 mt-5">
          <table>
            <thead className="border-b ">
              <tr className="">
                <th className="text-left pr-20">Id</th>
                <th className="text-left py-3 pr-10">Name</th>
                <th className="text-left py-3 pr-10">email</th>
                <th className="text-left py-3 pr-20">role</th>
                <th className="text-left py-3 pr-20">Action</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((item) => (
                  <tr className="" key={item._id}>
                    <td className="text-left py-3 pr-20">
                      {item._id.slice(0, 8)}
                    </td>
                    <td className="text-left py-3 pr-20">{item?.fullname}</td>
                    <td className="text-left py-3 pr-20">{item?.email}</td>
                    <td className="text-left py-3 pr-20">{item?.role}</td>
                    <td className=" flex gap-2 items-center text-left py-3 pr-20">
                      <p className="text-green-500 underline">View</p>
                      <Link to={`/updateuser/${item?._id}`} >
                        <p className="text_blue underline">Edit</p>

                      </Link>
                      <FaRegTrashAlt className="trash_icon text-red-600 cursor-pointer" onClick={()=> deleteUser(item._id)}/>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Layout>}

    </>
  );
}
