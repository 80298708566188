import { BiSolidDashboard } from "react-icons/bi";
import { FaUsers, FaWallet, FaNewspaper } from "react-icons/fa";
import logo from "./../assets/images/TradeGenie_Logo__1_-removebg-preview.png"
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

export default function Sidebar() {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);

  const handlePageClick = (path) => {
    setActivePage(path);
  }

  return (
    <div className="sidebarwidth  overflow_scroll">
      <div className="w-full    border h-full p-3 ">
        <img src="" className="" alt="TRADEGENIE" />

        <div className="mt-10">
          <div className={`flex gap-5 items-center Itemhover p-3 px-5 ${activePage === '/dashboard' ? 'active' : ''}`}>
            <BiSolidDashboard className="text-2xl"/>
            <Link to="/dashboard" onClick={() => handlePageClick('/dashboard')}>
              <p className="text-lg">Dashboard</p>
            </Link>
          </div>

          <div className={`flex gap-5 items-center Itemhover mt-3 p-3 px-5 ${activePage === '/currencies' ? 'active' : ''}`}>
            <FaWallet className="text-2xl"/>
            <Link to="/currencies" onClick={() => handlePageClick('/currencies')}>
              <p className="text-lg"> Currencies</p>
            </Link>
          </div>

          <div className={`flex gap-5 items-center Itemhover mt-3 p-3 px-5 ${activePage === '/users' ? 'active' : ''}`}>
            <FaUsers className="text-2xl"/>
            <Link to="/users" onClick={() => handlePageClick('/users')}>
              <p className="text-lg"> Users</p>
            </Link>
          </div>

          <div className={`flex gap-5 items-center p-3 px-5 mt-3 Itemhover ${activePage === '/view-newsletter' ? 'active' : ''}`}>
            <FaWallet className="text-2xl"/>
            <Link to="/view-newsletter" onClick={() => handlePageClick('/view-newsletter')}>
              <p className="text-lg whitespace-nowrap">Transactions</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
