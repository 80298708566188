import Layout from "../component/Layout";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../component/Loader";
import Swal from "sweetalert2";
import { BASE_URL } from "./../Variable"

export default function Edituser() {
  const [user, setUser] = useState();
  const [err, setErr] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleVal = () => {
    if (!user?.fullname || !user?.email || !user?.role) {
      return setErr(true);
    }
    updateUser();
  };

  const getUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(`${BASE_URL}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const updateUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(`${BASE_URL}/user/${id}`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res)
      Swal.fire({
        title: "Success!",
        text: "user updated!",
        icon: "success",
      });
      navigate("/users");
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log(user);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="flex justify-between">
            <h1>Update user</h1>
            <div className="flex gap-5">
              <div>
                <button className="bg-gray-500 text-white px-5 py-1 rounded">
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="color_blue text-white px-5 py-1 rounded"
                  onClick={() => handleVal()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <hr className="mt-3 border-gray-300" />

          <div className="bg-white h-auto w-full py-10 mt-5 px-5">
            <div className="form2 ">
              {!user?.name && err && (
                <span className="err_msg">please enter user name</span>
              )}
              <input
                type="text"
                name="fullname"
                value={user?.fullname}
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleSubmit(e)}
              />

              <label htmlFor="email" className="form_label">
                Fullname
              </label>
            </div>

            <div className="form">
              {!user?.rate && err && (
                <span className="err_msg">please enter user rate</span>
              )}
              <input
                type="text"
                name="email"
                value={user?.email}
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleSubmit(e)}
              />

              <label htmlFor="email" className="form_label">
                email
              </label>
            </div>

            <div className="form ">
              {!user?.symbol && err && (
                <span className="err_msg">please enter user symbol</span>
              )}
              <input
                type="text"
                name="role"
                className="form_input"
                value={user?.role}
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleSubmit(e)}
              />

              <label htmlFor="email" className="form_label">
                Role
              </label>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
