import ClipLoader from "react-spinners/ClipLoader";

export default function Loader() {
  return (
    <>
      <div className="w-screen h-screen bg-white flex justify-center items-center">
        <ClipLoader color="#1915f6"  size={90}         aria-label="Loading.." />
      </div>
    </>
  );
}
